.header {
    display: flex;
    align-items: center;
    background-color: #000000;
    padding: 10px;
  }
  
  .logo {
    height: 50px; /* Adjust as per your logo's dimensions */
    margin-right: 20px;
  }
  
  .title {
    color:aliceblue;
    margin-right: auto;
  }
  
  .live-indicator {
    display: flex;
    align-items: center;
    color: red; /* For the 'Live' text */
    font-weight: bold;
  }
  
  .rec-icon {
    width: 15px;
    height: 15px;
    background-color: red;
    border-radius: 50%; /* Makes it circular */
    margin-left: 5px;
  }
  